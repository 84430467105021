import {BarType} from '@/status_pages/display/components/uptimebar/types';
import {UptimeBar} from '@/status_pages/display/components/uptimebar/UptimeBar';
import {DateHistoryItem, StatusPage} from '@/status_pages/types';
import {UptimeBarPill} from '@/status_pages/display/components/uptimebar/defaults';
import {addSeconds, parseISO, startOfDay} from 'date-fns';
import toPairs from 'lodash/toPairs';

export interface InspireDateRangeSnakeProps {
  historyStartDate: object;
  historyEndDate: object;
  statuspage: StatusPage;
}

export const InspireDateRangeSnake = ({statuspage, historyStartDate, historyEndDate}: InspireDateRangeSnakeProps) => {
  const events = toPairs(statuspage.date_history)
    .filter(([k, v]) => v.downtime_secs)
    .map(([k, v]) => ({
      end: addSeconds(startOfDay(parseISO(k)), v.downtime_secs),
      start: startOfDay(parseISO(k)),
      className: UptimeBarPill.DANGER,
    }));
  return (
    <UptimeBar
      height={60}
      start={historyStartDate}
      end={historyEndDate}
      events={events}
      barType={BarType.Day}
      // config={{...defaultBarOptions, tooltip: debugTooltip, defaultClassName: 'pill'}}
      timezone={statuspage.used_timezone}
    />
  );
};
