import {isAfter, isBefore, isEqual} from "date-fns";

export const compareDates = (a: Date, b: Date): number => {
  return a.getTime() - b.getTime();
};

type TypeComparer<T> = (a: T, b: T) => number;

export const objectDatesComparer = <T,>(getter: (obj: T) => Date): TypeComparer<T> => {
  return (a: T, b: T): number => {
    return compareDates(getter(a), getter(b));
  };
};

export const isAfterOrEqual = (date: Date, dateToCompare: Date): boolean => {
  return isEqual(date, dateToCompare) || isAfter(date, dateToCompare);
};

export const isBeforeOrEqual = (date: Date, dateToCompare: Date): boolean => {
  return isEqual(date, dateToCompare) || isBefore(date, dateToCompare);
};
