import moment from "moment-timezone";

export const formatDailyLabel = (d: Date, timezone?: string): string =>
  moment(d)
    .tz(timezone || "UTC")
    .format("MMM DD");

export const formatHourlyLabel = (d: Date, timezone?: string): string =>
  moment(d)
    .tz(timezone || "UTC")
    .format("hh a");
