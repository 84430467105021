import {Duration, formatISO} from "date-fns";
import {BarData, BarOptions} from "./types.ts";

export const HourlyDuration: Duration = {minutes: 10};
export const DailyDuration: Duration = {hours: 4};

export enum UptimeBarPill {
  SUCCESS = "pill-success",
  DANGER = "pill-danger",
  WARNING = "pill-warning",
  NONE = "pill-secondary",
  INFO = "pill-info",
  COLOR_ORANGE = "pill-orange",
  COLOR_PURPLE = "pill-purple",
}

export const defaultBarOptions: BarOptions = {
  defaultClassName: UptimeBarPill.SUCCESS,
  skipAggregationNames: [UptimeBarPill.SUCCESS, UptimeBarPill.NONE],
  onHoverColor: "lightgray",
  hourlyDuration: HourlyDuration,
  dailyDuration: DailyDuration,
};

/*
A function to transform bar data into HTML as a string. An alternative is text-only
 */
export const debugTooltip = ({interval, weight, event}: BarData): string => {
  let response = ` <small class="d-flex flex-column">
      <span class="me-1">weight: ${weight}</span>
      <span class="me-1">${formatISO(interval.start)} - ${formatISO(interval.end)}</span>`;

  if (event) {
    response += `
      <span class="me-1 fw-semibold">${formatISO(event.start)} - ${event?.end ? formatISO(event.end) : ""}</span>
      <span class="me-1">${event.className}</span>
    `;
  }

  response += `</span>`;
  return response;
};
