import {BarData} from './types.ts';
import {BaseType, select} from 'd3';
import {Selection} from 'd3-selection';
import isFunction from 'lodash/isFunction';

export interface TooltipHelpers {
  mouseover: (e: MouseEvent) => void;
  mousemove: (e: MouseEvent) => void;
  mouseleave: (e: MouseEvent) => void;
}

interface TooltipProps<T extends BaseType> {
  tooltip: Selection<T, unknown, null, undefined>;
  getItem: (id: string) => BarData | undefined;
  config: {
    onHoverColor: string;
  };
  render?: (data: BarData) => string;
  marginTop?: number;
}

const TOOLTIP_ID = 'svg-tooltip';

export const getTooltipHandlers = <T extends BaseType>({
  tooltip,
  getItem,
  config,
  render,
  marginTop = 15,
}: TooltipProps<T>): TooltipHelpers => {
  tooltip
    .style('opacity', 0)
    .attr('id', TOOLTIP_ID)
    .attr('class', 'tooltip')
    .style('position', 'fixed')
    .style('background-color', 'white')
    .style('border', 'solid')
    .style('border-width', '1px')
    .style('border-radius', '5px')
    .style('padding', '5px');

  return {
    mouseover: function (e: MouseEvent) {
      if (isFunction(render)) {
        tooltip.style('opacity', 1);
      }
      select(e.currentTarget as SVGElement)
        .style('stroke', config.onHoverColor)
        .style('opacity', 1);
    },
    mousemove: function (e: MouseEvent) {
      if (isFunction(render)) {
        const uniqueId = select(e.currentTarget as SVGElement).attr('id');
        const item = getItem(uniqueId);
        if (!item) return;

        const ht = tooltip.html(render(item));
        // @ts-expect-error TS2339
        const tooltipHeight = ht._groups[0][0].getBoundingClientRect().height;
        ht.style('left', e.clientX + 'px').style('top', e.clientY - tooltipHeight - marginTop + 'px');
      }
    },
    mouseleave: function (e: MouseEvent) {
      if (isFunction(render)) {
        tooltip.style('opacity', 0);
      }
      select(e.currentTarget as SVGElement)
        .style('stroke', 'none')
        .style('opacity', 0.8);
    },
  };
};
