import {formatDailyLabel, formatHourlyLabel} from './legend.ts';
import {findOverlappingEvents, splitBarByEvents} from './tools.ts';
import {getTooltipHandlers} from './tooltip.tsx';
import {add, addDays, addHours, differenceInDays, differenceInHours, Duration} from 'date-fns';
import {useState} from 'react';
import {roundedRectPath, useD3} from './d3utils.ts';
import {defaultBarOptions} from './defaults.ts';
import {BarData, BarEvent, BarOptions, BarType} from './types.ts';
import './UptimeBar.css';

const SCALE_BARS_AFTER = 7;
const BAR_PARTS = 6;

export interface UptimeBarProps {
  start: Date;
  end: Date;
  barType: BarType;
  config?: BarOptions;
  events?: BarEvent[];
  width?: number;
  height?: number;
  timezone?: string;
}

/**
 *
 *     7 days, 6 chunks high (24 / 6 -> 4h)
 *
 *     alert 1: Tuesday   14:00 -> Tuesday   14:05 = [ 00-04,   04:08,   08:12,  *12:16*,  16:20,  20:24]
 *     alert 2: Tuesday   23:05 -> Wednesday 04:55 = [*00-04*, *04:08*,  08:12,   12:16,   16:20,  20:24]
 *     maint 1: Wednesday 23:00 -> Thursday  17:00 = [^00-04^, ^04:08^, ^08:12^, ^12:16^, ^16:20^, 20:24]
 *     alert 3: Friday    00:05 -> Friday    03:00 = [*00-04*,  04:08,   08:12,   12:16,   16:20,  20:24]
 *     down  1: Friday    12:00 -> Friday    16:00 = [ 00-04,   04:08,   08:12,  #12:16#,  16:20,  20:24]
 *
 *     no-icon   bell      bell?    maint    down?
 *        M        T        W        T        F        S        S
 *     |------| |******| |^^^^^^| |------| |------| |------| |------|
 *     |------| |------| |------| |^^^^^^| |------| |------| |------|
 *     |------| |******| |------| |^^^^^^| |######| |------| |------|
 *     |------| |------| |------| |^^^^^^| |------| |------| |------|
 *     |------| |------| |******| |^^^^^^| |------| |------| |------|
 *     |------| |------| |******| |^^^^^^| |******| |------| |------|
 *
 * @param bar single bar config
 * @param width increase width if path loses round borders and reflects itself across X axis
 * @param events events to split bars into. Overlapping events are not supported.
 * @constructor
 */
export const UptimeBar = ({
  width = 600,
  height = 100,
  config: barConfig,
  start,
  end,
  events,
  barType,
  timezone,
}: UptimeBarProps) => {
  const [data] = useState<Map<string, BarData>>(new Map<string, BarData>());
  const isHourly = barType === BarType.Hour;
  const bars = isHourly ? differenceInHours(end, start) : differenceInDays(end, start);
  const labelFormatter = isHourly ? formatHourlyLabel : formatDailyLabel;
  const needsScale = bars > SCALE_BARS_AFTER;
  const labelEveryN = needsScale ? 4 : 1;

  const ref = useD3<HTMLDivElement>(
    (root) => {
      // clean before render
      root.selectAll('*').remove();

      const svg = root.append('svg').attr('width', '100%').attr('viewBox', `-5 0 ${width} ${height}`);

      const marginRight = 10;
      const barWidth = Math.ceil(width / bars) - marginRight;
      const barHeight = height * 0.6;
      const marginTop = height * 0.3;
      const shiftBottom = Math.ceil(barHeight / 4);
      const container = svg.append('g').attr('transform', `translate(0,${-shiftBottom})`);
      const tickDuration: Duration = isHourly ? {hours: 1} : {days: 1};
      let tickStart = start;

      const config = barConfig ?? defaultBarOptions;
      const borderRadius = config.borderRadius || 8;

      const {mouseover, mousemove, mouseleave} = getTooltipHandlers({
        // we need to keep a reference to the element in this scope
        tooltip: root.append('div'),
        getItem: (id) => data.get(id),
        render: config.tooltip,
        config: {
          onHoverColor: config.onHoverColor,
        },
      });
      for (let barIdx = 0; barIdx < bars; barIdx++) {
        const xPos = barWidth * barIdx + barIdx * marginRight;
        const indicators = new Set<string>();
        const tickEnd = isHourly ? addHours(tickStart, 1) : addDays(tickStart, 1);
        const overlappingEvents = findOverlappingEvents({
          interval: {start: tickStart, end: tickEnd},
          events: events,
          sort: true,
        });

        const hasFreeSpaceAtTheEnd = barIdx === bars - 1 && barIdx % labelEveryN > 2;
        const addText = (idx: number, labelEveryN: number, hasFreeSpaceAtTheEnd: boolean) => {
          if (idx % labelEveryN !== 0 && !hasFreeSpaceAtTheEnd) {
            return;
          }

          const x = xPos + (needsScale ? 0 : marginRight) + (hasFreeSpaceAtTheEnd ? -marginRight : 0);
          const y = barHeight + marginTop + shiftBottom;
          const el = container.append('text').attr('x', x).attr('y', y).attr('font-size', 'x-small');
          el.text(labelFormatter(tickStart, timezone));
        };
        if (overlappingEvents.length > 0) {
          const pieces = splitBarByEvents({
            start: tickStart,
            end: tickEnd,
            duration: isHourly ? config.hourlyDuration : config.dailyDuration,
            events: overlappingEvents,
          });
          pieces
            .filter((p) => p.event?.className && !config.skipAggregationNames.includes(p.event?.className))
            .forEach((p) => indicators.add(p.event!.className!));
          let currentY = height * 0.2;
          Array.from(indicators)
            .reverse()
            .forEach((className) => {
              container
                .append('circle')
                .attr('cy', currentY)
                .attr('cx', xPos + barWidth / 2)
                .attr('r', needsScale ? 3 : 4)
                .attr('class', className);
              currentY += needsScale ? 2 : 3;
            });

          let currentHeight = marginTop;
          for (let pieceId = 0; pieceId < pieces.length; pieceId++) {
            const piece = pieces[pieceId];
            const pieceHeight = height * piece.weight * 0.1;
            const roundedBottom = pieceId == 0;
            const roundedTop = pieceId === pieces.length - 1;

            const restHeight = pieces.filter((e) => e !== piece).reduce((s, i) => s + height * i.weight * 0.1, 0);
            currentHeight += restHeight;
            const uniqueID = `${barIdx}-sub-${pieceId}`;
            data.set(uniqueID, {
              interval: piece.interval,
              event: piece.event,
              weight: piece.weight,
            });

            container
              .append('path')
              .attr('id', uniqueID)
              .attr(
                'd',
                roundedRectPath(
                  xPos,
                  currentHeight,
                  barWidth,
                  pieceHeight,
                  borderRadius,
                  roundedTop,
                  roundedTop,
                  roundedBottom,
                  roundedBottom
                )
              )
              .attr('class', piece.event?.className ?? config.defaultClassName)
              .on('mouseover', mouseover)
              .on('mousemove', mousemove)
              .on('mouseleave', mouseleave);
            addText(barIdx, labelEveryN, hasFreeSpaceAtTheEnd);

            currentHeight -= restHeight + pieceHeight;
          }

          tickStart = add(tickStart, tickDuration);
          continue;
        }
        const uniqueID = `${barIdx}-main`;
        data.set(uniqueID, {
          interval: {
            start: tickStart,
            end: tickEnd,
          },
          weight: BAR_PARTS,
        });

        container
          .append('rect')
          .attr('width', barWidth)
          .attr('id', uniqueID)
          .attr('height', barHeight)
          .attr('rx', borderRadius)
          .attr('x', xPos)
          .attr('y', marginTop)
          .attr('class', config.defaultClassName)
          .on('mouseover', mouseover)
          .on('mousemove', mousemove)
          .on('mouseleave', mouseleave);
        addText(barIdx, labelEveryN, hasFreeSpaceAtTheEnd);

        tickStart = add(tickStart, tickDuration);
      }
    },
    [width, height, bars, barConfig, start, end, events]
  );
  return <div ref={ref} className="snake" />;
};
