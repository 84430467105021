import {Duration, Interval} from "date-fns";

export interface BarData {
  interval: Interval;
  event?: BarEvent;
  weight: number;
}

export interface BarOptions {
  defaultClassName: string;
  onHoverColor: string;
  tooltip?: (data: BarData) => string;
  borderRadius?: number;
  skipAggregationNames: string[];
  hourlyDuration: Duration;
  dailyDuration: Duration;
}

export interface BarEvent {
  start: Date;
  end?: Date;
  className?: string;
}

export enum BarType {
  Hour = "hour",
  Day = "day",
}
