/* d3 react integration helpers */

import {select} from "d3";
import {Selection} from "d3-selection";
import {DependencyList, useEffect, useRef} from "react";

/*
usage example:

const ref = useD3<SVGSVGElement>((refElement) => {
  // do not forget to clear element before rendering refElement.selectAll("*").remove()
  // proceed with render function
}, deps]
return <svg ref=ref />
}

inspired by: https://www.pluralsight.com/resources/blog/guides/using-d3js-inside-a-react-app
 */
export const useD3 = <T extends Element>(
  renderChartFn: (svg: Selection<T, unknown, null, undefined>) => void,
  deps?: DependencyList
) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    renderChartFn(select(ref.current as T));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return ref;
};

/*
x: x-coordinate
y: y-coordinate
w: width
h: height
r: corner radius
tl: top_left rounded?
tr: top_right rounded?
bl: bottom_left rounded?
br: bottom_right rounded?
 */
export const roundedRectPath = (
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  tl?: boolean,
  tr?: boolean,
  bl?: boolean,
  br?: boolean
) => {
  let retval: string;
  retval = "M" + (x + r) + "," + y;
  retval += "h" + (w - 2 * r);
  if (tr) {
    retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r;
  } else {
    retval += "h" + r;
    retval += "v" + r;
  }
  retval += "v" + (h - 2 * r);
  if (br) {
    retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
  } else {
    retval += "v" + r;
    retval += "h" + -r;
  }
  retval += "h" + (2 * r - w);
  if (bl) {
    retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
  } else {
    retval += "h" + -r;
    retval += "v" + -r;
  }
  retval += "v" + (2 * r - h);
  if (tl) {
    retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
  } else {
    retval += "v" + -r;
    retval += "h" + r;
  }
  retval += "z";
  return retval;
};
