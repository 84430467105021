import DateRangeSnake from '@/status_pages/display/DateRangeSnake';
import Formatter from '@/jskit/general/Formatter';
import ReactUtils from '@/jskit/react/ReactUtils';
import {ComponentStatusValue, Incident, StatusPage} from '@/status_pages/types';
import css from './CurrentStatusMetrics.module.css';
import {InspireDateRangeSnake} from '@/status_pages/display/components/theme-specific/inspire/InspireDateRangeSnake';

export interface CurrentStatusMetricsProps {
  statuspage: StatusPage;
  componentStatusRank: Record<ComponentStatusValue, number>;
  historyStartDate: object;
  historyEndDate: object;
  handleDateRangeSnakeClick: (date: any, e: any) => void;
  lastIncident: Incident | null;
}

export const CurrentStatusMetrics = ({
  statuspage,
  componentStatusRank,
  historyStartDate,
  historyEndDate,
  handleDateRangeSnakeClick,
  lastIncident,
}: CurrentStatusMetricsProps) => {
  return (
    <div className="row mb-4">
      <div className="col-12 col-lg-8">
        <InspireDateRangeSnake
          historyStartDate={historyStartDate}
          historyEndDate={historyEndDate}
          statuspage={statuspage}
        />
      </div>
      <div className="col-12 col-lg-4 text-muted">
        <div className="row">
          <div className={ReactUtils.cssClass('col-12  text-center text-lg-left', css.IncidentLabel)}>
            <small>Last Incident:</small>
          </div>
        </div>
        <div className="row">
          <div
            className={ReactUtils.cssClass(
              'col-12 col-lg-6 text-center text-lg-left d-block text-truncate text-wrap',
              css.IncidentName
            )}
          >
            {lastIncident ? lastIncident?.name : 'No incidents recorded'}
          </div>
          <div className={ReactUtils.cssClass('col-12 col-lg-6 text-center text-lg-left', css.IncidentDuration)}>
            {lastIncident
              ? lastIncident.ends_at
                ? Formatter.shortDateTime(lastIncident.starts_at, `${window.CURRENT_TIME_FORMAT}_NO_SECONDS`)
                : `Ongoing for ${Formatter.shortDuration(lastIncident.duration)}`
              : `Since ${Formatter.shortDateTime(historyStartDate, `${window.CURRENT_TIME_FORMAT}_NO_SECONDS`)}`}
          </div>
        </div>
      </div>
    </div>
  );
};
