import {isAfterOrEqual, isBeforeOrEqual, objectDatesComparer} from './dateUtils.tsx';
import {BarEvent} from './types.ts';
// import {captureMessageForReporting} from "@/lib/errorReporting.tsx";
import {
  add,
  areIntervalsOverlapping,
  Duration,
  format,
  Interval,
  min,
  NearestHours,
  NearestMinutes,
  roundToNearestHours,
  roundToNearestMinutes,
} from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';

interface PieceConstraints {
  start: Date;
  end: Date;
  duration: Duration;
  events: BarEvent[];
  amount?: number;
}

export interface BarPiece {
  interval: Interval;
  weight: number;
  event?: BarEvent;
  /**
   events can be filled with splitBarByBuckets algorithm, currently we use 1 event per interval
   */
  events?: BarEvent[];
}

/*
Ensure every bucket of 6 is filled with event that overlaps with it.
for instance:

*     |Y-----| <- fill with Y color
*     |-YYYYY| <- fill with Y color
*     |------|
// color both buckets below, as event overlaps with them
*     |XXX---|  <-- end here
*     |---XXX|  <-- starts here
*     |------|
 */
export const splitBarByEvents = ({start, end, duration, events, amount = 6}: PieceConstraints): BarPiece[] => {
  const pieces: BarPiece[] = [];

  const sortedEvents = cloneDeep(events).sort(objectDatesComparer<BarEvent>((a) => a.start));
  guardOverlappingEvents(events, end);

  const overlapped: BarEvent[] = findOverlappingEvents({interval: {start, end}, events: sortedEvents});
  const tickStart = start;
  const tickEnd = add(tickStart, duration);
  let tick: BarPiece = {weight: 1, interval: {start: tickStart, end: tickEnd}};
  let currentEvent: BarEvent = overlapped[0];
  if (currentEvent && currentEvent.start <= start && (!currentEvent.end || end <= currentEvent.end)) {
    return [{weight: amount, interval: {start, end}, event: currentEvent}];
  }

  while (currentEvent) {
    /*
    current event started and has not ended yet.
    merge ticks until event starts and fill the rest of space with current event
     */
    if (!currentEvent.end || end <= currentEvent.end) {
      let nextStep = min([add(tick.interval.end, duration), end]);
      while (nextStep < currentEvent.start) {
        tick.interval.end = nextStep;
        tick.weight++;
        nextStep = min([add(tick.interval.end, duration), end]);
      }
      pieces.push(tick);
      tick = {
        weight: amount - pieces.reduce((n, {weight}) => n + weight, 0),
        interval: {start: tick.interval.end, end: min([currentEvent.end || end, end])},
        event: currentEvent,
      };
      pieces.push(tick);
      return pieces;
    }

    /*
    tick ends before current event starts
    keep merging ticks until it starts
     */
    if (tick.interval.end < currentEvent.start) {
      let nextStep = min([add(tick.interval.end, duration), end]);
      while (nextStep < currentEvent.start) {
        tick.interval.end = nextStep;
        tick.weight++;
        nextStep = add(tick.interval.end, duration);
      }
      pieces.push(tick);
      tick = {weight: 1, interval: {start: tick.interval.end, end: min([add(tick.interval.end, duration), end])}};
      // add empty tick, currentEvent has not been processed yet
      continue;
    }

    /*
    an edge case if tick current tick ends within current event
     */
    if (
      tick.interval.end <= currentEvent.end ||
      (tick.interval.start < currentEvent.start && currentEvent.end < tick.interval.end)
    ) {
      while (tick.interval.end < currentEvent.end) {
        tick.interval.end = min([add(tick.interval.end, duration), end]);
        tick.weight++;
      }
      tick.event = currentEvent;
      pieces.push(tick);
      tick = {weight: 1, interval: {start: tick.interval.end, end: min([add(tick.interval.end, duration), end])}};
    }

    overlapped.shift();
    currentEvent = overlapped[0];
  }

  // fill any space that's left
  const spaceLeft = amount - pieces.reduce((n, {weight}) => n + weight, 0);
  if (spaceLeft && !currentEvent) {
    tick.interval.end = end;
    tick.weight = spaceLeft;
    pieces.push(tick);
    return pieces;
  }

  // if (pieces.length === 0) {
  //   captureMessageForReporting(`uptime-bar: could not generate ticks for bar at ${start} - ${end}`);
  // }

  return pieces;
};

const guardOverlappingEvents = (events: BarEvent[], end: Date) => {
  for (let i = 0; i < events.length; i++) {
    const current = events[i];
    const next = events[i + 1];
    if (
      next &&
      areIntervalsOverlapping(
        {
          start: current.start,
          end: current.end ?? end,
        },
        {
          start: next.start,
          end: next.end ?? end,
        }
      )
    ) {
      throw new Error(`overlapping events are not supported, start: ${current.start}, end: ${current.end ?? end}`);
    }
  }
};

export const findOverlappingEvents = ({
  interval,
  events,
  sort,
}: {
  interval: Interval;
  events?: BarEvent[];
  sort?: boolean;
}): BarEvent[] => {
  let source = events || [];
  if (source.length === 0) {
    return [];
  }

  if (sort) {
    source = cloneDeep(source).sort(objectDatesComparer<BarEvent>((a) => a.start));
  }

  return source.filter((e) => {
    return areIntervalsOverlapping(interval, {start: e.start, end: e.end || interval.end});
  });
};

/*
an original brute force algorithm to split bar into pieces.
Useful in debugging and troubleshooting
 */
export const splitBarAndMerge = ({start, duration, events, amount = 6}: PieceConstraints): BarPiece[] => {
  const pieces: {interval: Interval; event?: BarEvent}[] = [];
  let subTickStart = start;
  for (let subTickIdx = 0; subTickIdx < amount; subTickIdx++) {
    const subTickEnd = add(subTickStart, duration);
    const tickEvent = events.find((e) => {
      const startBetween = isAfterOrEqual(subTickStart, e.start) && (!e.end || isBeforeOrEqual(subTickStart, e.end));
      const endBetween = isAfterOrEqual(subTickEnd, e.start) && (!e.end || isBeforeOrEqual(subTickEnd, e.end));
      return startBetween || endBetween;
    });
    pieces.push({interval: {start: subTickStart, end: subTickEnd}, event: tickEvent});
    subTickStart = add(subTickStart, duration);
  }

  // merge pieces
  const subBars: {weight: number; interval: Interval; event?: BarEvent}[] = [];
  let currentBar = {
    weight: 1,
    event: pieces[0].event,
    interval: pieces[0].interval,
  };
  for (let subTickIdx = 1; subTickIdx < amount; subTickIdx++) {
    const piece = pieces[subTickIdx];
    if (currentBar.event === piece.event) {
      currentBar.weight++;
      currentBar.interval.end = piece.interval.end;
    } else {
      subBars.push(currentBar);
      currentBar = {
        weight: 1,
        interval: piece.interval,
        event: piece.event,
      };
    }
  }

  if (subBars.length < 1 || currentBar.event != subBars[subBars.length - 1].event) {
    subBars.push(currentBar);
  }

  return subBars;
};

/*
generate buckets given start, end and interval. Assign events to every bucket they cross in any way.
Function isn't used yet, as it's not clear how to merge buckets in case multiple events cross multiple buckets.
 */
export const splitBarByBuckets = ({start, end, duration, events}: PieceConstraints): Map<string, BarEvent[]> => {
  const computeBucketKey = (d: Date, duration: Duration): string => {
    if (duration.hours) {
      d = roundToNearestHours(d, {nearestTo: duration.hours as NearestHours});
    }
    if (duration.minutes) {
      d = roundToNearestMinutes(d, {nearestTo: duration.minutes as NearestMinutes});
    }
    return `${format(d, 'yyyy-MM-dd HH:mm')}-${format(add(d, duration), 'yyyy-MM-dd HH:mm')}`;
  };
  const ticks: Interval[] = [];
  const buckets = new Map<string, BarEvent[]>();
  let bucketStart = start;
  while (bucketStart < end) {
    buckets.set(computeBucketKey(bucketStart, duration), []);
    const nextValue = add(bucketStart, duration);
    ticks.push({start: bucketStart, end: nextValue});
    bucketStart = nextValue;
  }

  events.forEach((event) => {
    const bucketKeys = ticks.filter((tick) => {
      return areIntervalsOverlapping(tick, {start: event.start, end: event.end || tick.end}, {inclusive: true});
    });
    bucketKeys.forEach((key) => {
      const bucketKey = computeBucketKey(key.start as Date, duration);
      const events = buckets.get(bucketKey) || [];
      buckets.set(bucketKey, events.concat([event]));
    });
  });

  return buckets;
};
