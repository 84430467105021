import {ReactNode} from 'react';
import {GlobalStatus} from '@/status_pages/display/components/theme-specific/inspire/GlobalStatus';
import {IncidentType, StatusPage, StatusPageGlobalStatus} from '@/status_pages/types';
import moment from 'moment-timezone';
import ReactUtils from '@/jskit/react/ReactUtils';
import {InspireLogo} from '@/status_pages/display/components/theme-specific/inspire/InspireLogo';
import {InspireNavItems} from '@/status_pages/display/components/theme-specific/inspire/InspireNavItems';
import css from './InspireHeader.module.css';

export interface HeaderProps {
  statuspage: StatusPage;
  toolbar: ReactNode;
  historyStartDate: object;
}

export const InspireHeader = ({statuspage, toolbar, historyStartDate}: HeaderProps) => {
  const formattedTimezoneOffset = moment().tz(statuspage.used_timezone).format('Z');
  const navItems = []; // TODO: once available, pass them

  const activeIncidents = statuspage.active_incidents.filter((incident) => incident.incident_type === 'INCIDENT');
  const activeMaintenance = statuspage.active_incidents.filter(
    (incident) => incident.incident_type === IncidentType.SCHEDULED_MAINTENANCE
  );
  const lastActiveIncident = activeIncidents.at(-1);
  const lastActiveMaintenance = activeMaintenance.at(0);
  const lastPastIncident = (statuspage.past_incidents || [])
    .filter((incident) => incident.incident_type === IncidentType.INCIDENT)
    .at(0);
  let globalStatus, globalStatusDuration;
  if (activeIncidents.length == 0 && activeMaintenance.length == 0 && statuspage.global_is_operational) {
    globalStatus = StatusPageGlobalStatus.OPERATIONAL;
    if (lastPastIncident) {
      globalStatusDuration = moment.duration(moment().diff(moment(lastPastIncident.ends_at))).asSeconds();
    } else {
      globalStatusDuration = moment.duration(moment().diff(moment(historyStartDate))).asSeconds();
    }
  } else if (activeIncidents.length > 0) {
    globalStatus = StatusPageGlobalStatus.ISSUES;
    globalStatusDuration = lastActiveIncident.duration;
  } else if (activeMaintenance.length > 0) {
    globalStatus = StatusPageGlobalStatus.MAINTENANCE;
    globalStatusDuration = moment.duration(moment(lastActiveMaintenance.ends_at).diff(moment())).asSeconds();
  } else if (statuspage.has_components_under_critical_state) {
    globalStatus = StatusPageGlobalStatus.ISSUES;
  } else if (statuspage.has_components_under_maintenance_state) {
    globalStatus = StatusPageGlobalStatus.MAINTENANCE;
  }

  return (
    <div className={css.Header}>
      <div className={ReactUtils.cssClass('pl-2 pr-2 pl-lg-6 pr-lg-6 pt-4 mb-5', css.HeaderContainer)}>
        <div className="row align-items-center">
          <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
            <div className="row">
              {statuspage.logo_url && (
                <div className="col-12 d-flex justify-content-md-start justify-content-center mb-4">
                  <InspireLogo
                    name={statuspage.name}
                    logoUrl={statuspage.logo_url}
                    companyWebsiteUrl={statuspage.company_website_url}
                  />
                </div>
              )}
              <div className="pt-2 pt-md-0 col-12 text-center text-md-left">
                <h1 className={css.HeaderTitle}>{statuspage.name}</h1>
              </div>
              <div className="col-12 text-center text-md-left">
                <div className={css.HeaderSubtitle}>
                  Timezone: (GMT {formattedTimezoneOffset}) {statuspage.used_timezone}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-4 mt-md-0">
            <InspireNavItems items={navItems} />
          </div>
          <div className="col-12 col-md-4 pl-0 align-self-start">{toolbar}</div>
        </div>
        <div>
          <GlobalStatus status={globalStatus} durationSecs={globalStatusDuration} />
        </div>
      </div>
    </div>
  );
};
